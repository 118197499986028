import Dh from './pics/DillonHall.jpg'
import Footer from './Footer';
import THeader from './TopHeader';

export default function Education() {
    return(

        <div>
            <head>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1461898983747739"
     crossorigin="anonymous"></script>
            </head>
            <div className='container' >
            <THeader />
            <br />
            <div >
                <img src={Dh} className="img-fluid rounded mx-auto d-block" height='auto' width='600'></img>
            </div>
            <div>
                <h3 className='text  text-center m-1'>University Of Windsor</h3>
            </div>
            <br></br>
            <div>
            <h5><b>
                    Education
                    </b>
                </h5>
                <div>
                    <h6>University</h6>
                    <h7><b>University of Windsor (Ontario, Canada)</b></h7>
                    <i><p>BSc[H] Computer Science with Software Engineering Specialization and Minor in Business Administration</p></i>
                </div>
                <h6>Completed Bachelors in Computer Science from University of Windsor with Distinction. January 2018 - April 2021</h6>
                {/* <br />
                <div>
                    <h5><b>Courses Taken</b></h5>
                    <div className='float-start' style={{marginRight:'2%',marginBottom:'2%'}}>
                    <h6><b>Core Computer Science Courses</b></h6>
                        <li>Project Management Techniques and Tools (Final Year Project Course (2 semesters))</li>
                        <li>Selected Topics in Software Engineering</li>
                        <li>Software Verification and Testing</li>
                        <li>Principles of Programming Languages</li>
                        <li>Design and Analysis of Computer Algorithms</li>
                        <li>Operating Systems Fundamentals</li>
                        <li>Database Management Systems</li>
                        <li>Object Oriented Software Analysis and Design</li>
                        <li>Theory of Computation</li>
                        <li>Computer Networks</li>
                        <li>Object Oriented Programming using Java</li>
                        <li>Introduction to Software Engineering</li>
                    
                        <li>Software Development</li>
                        <li>Data Structures and Algorithms</li>
                        <li>Language, Grammar and Translators (Computer)</li>
                        <li>Computer Architecture I</li>
                        <li>Computer Architecture II</li>
                        <li>Theoretical Foundations of Computer Science</li>
                        <li>Systems Programming</li>
                    
                        <li>Key Concepts in Computer Science</li>
                        <li>Introduction to Programming I (Using C)</li>
                        <li>Introduction to Programming II (Using C)</li>
                    
                    </div>
                    <div>
                    <h6><b>Required Mathematical courses</b></h6>
                        <li>Differential Calculus</li>
                        <li>Linear Algebra 1</li>
                        <li>Integral Calculus</li>
                        <li>Statistics for the Sciences</li>
                        <li>Mathematical Foundations</li>
                        
                    </div>
                    <div style={{marginTop:'2%'}}>
                    <h6><b>Business Minor</b></h6>
                        <li>Introduction to Economics 1</li>
                        <li>Management and Organizational Life</li>
                        <li>Business Finance 1</li>
                        <li>Principles of Managerial Accounting</li>
                        <li>Principles of Marketing</li>
                        <li>Principles of Financial Accounting</li>
                        <li>Introduction to Business</li>
                    </div>
                    <div style={{marginTop:'2%'}}>
                    <h6><b>Electives</b></h6>
                        <li>World Wide Web Information System Development</li>
                        <li>Programming for Beginners (Python programming introduction)</li>
                        <li>Introduction to Media Design and Production 1</li>
                        <li>Topics in Literature: Frankenstein's Monsters</li>
                        <li>Introduction to Psychology and Behavioural Sciences</li>
                        </div>
                </div> */}
                <br />
            </div>
            </div>

            <div>
            <div>
                {/* <Footer /> */}
                </div>
            </div>
        </div>
    );
};
