import emailjs from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import THeader from './TopHeader';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import Footer from './Footer';
export default function ContactMe() {

    
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_sno7gno', 'template_tnuyzk6', e.target, 'user_icjZIwthvlWqjV8GAMvTX')
          .then((result) => {
              console.log(result.text);
              alert("Message Sent! Thank you for contacting me, I will reply shortly.");
          }, (error) => {
              console.log(error.text);
              alert("Message cannot be sent at the moment! Please contact me via phone or click on the email below to take you to the mail app. Sorry for the inconvenience!");
          });
          e.target.reset();
      }

    return(

        
        <div>
            <head>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5278266834371400"
     crossorigin="anonymous"></script>
            </head>
        <div className='container'>
              <THeader />
            <div className='container' style={{backgroundColor:'ghostwhite',borderRadius:'20px',marginTop:'20px',marginBottom:'23%',width:'100%'}}>
            <div className='divStyle' style={{width:'100%'}}>
                <h5 className=' flex flex-center'>Contact me</h5>
        <div>
            <div className="container" style={{width:'100%'}}>
                <form onSubmit={sendEmail} style={{width:'100%'}}>
                    <div className="row pt-4 mx-auto">
                        <div className="col-8 form-group mx-auto">
                            <input type="text" className="form-control" placeholder="Your Name" name="name"/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <input type="email" className="form-control" placeholder="Your Email Address" name="email" required/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <input type="text" className="form-control" placeholder="Subject" name="subject"/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <textarea className="form-control" id="" cols="30" rows="8" placeholder="Your message" name="message" required></textarea>
                        </div>
                        <div className="col-8 pt-3 mx-auto">
                            <input type="submit" className="btn btn-primary" value="Send Message"></input>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <h5>OR</h5>
                <a className='btn btn-primary m-2' href='mailto:muhammadfarazsohail@outlook.com'>Email me</a>
                {/* <a className='btn btn-primary' href='tel:+12262469815'>+1(226)246-9815</a> */}
            </div>
            </div>
            <br />
            
        </div>
        <div className='mb-0'>
                {/* <Footer /> */}
            </div>
        </div>
    );
}
