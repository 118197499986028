
import React, {useEffect,useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Home from './Home';
import Projects from './Projects';
import Experience from './Experience';
import Contact from './ContactMe';
import Skill from './Skills';
import Edu from './Education';
import 'bootstrap';
import 'react-bootstrap';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText
} from 'reactstrap';

function App() {
  useEffect(()=>{
    document.title="Muhammad Faraz Sohail"
  },[])

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Router>
    <div className="App">
      {/**navbar */}
    <Navbar color='light'  light expand='md'>
          
    <NavbarBrand className='btn' href='/'> Muhammad Faraz Sohail </NavbarBrand>
    <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>

            
              <Nav navbar>
                <NavItem>
                    <NavLink className='btn' href='/'>Home</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className='btn' href='/edu' > Education </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className='btn' href='/projects'>  Projects </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className='btn' href='/experiences' > Experiences </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className='btn' href='/skills' > Skills  </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className='btn' className='nav-link' href='/contact' > Contact Me </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
        </Navbar>
    </div>

    {/**switch and routes */}
    <Switch>
      <Route path='/projects'>
        <Projects />
      </Route>
      <Route path='/experiences'>
        <Experience />
      </Route>
      <Route path='/contact'>
        <Contact />
      </Route>
      <Route path='/skills'>
        <Skill />
      </Route>
      <Route path='/edu'>
        <Edu />
      </Route>
      <Route path='/'>
        <Home />
      </Route>
    </Switch>
    </Router>
  );
}

export default App;
