import reactLogo from './logo.png';
import dotnetLogo from './pics/dotnetLogo.png';
import rnLogo from './pics/reactNativeLogo.png';
import sbLogo from './pics/springBootLogo.png';
import ktorLogo from './pics/ktorLogo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import THeader from './TopHeader';
import Footer from './Footer';
import res from './pics/resume.pdf'

export default function Skills() {
    return(
        <div>
        <div className='container' style={{marginBottom:'5%'}}>
            <THeader />
            <div className='divStyle'>
            <h3><b>Skills</b></h3>
            <h5><b>Non-Technical Skills</b></h5>
            <table className='table table-striped'>
            <tbody>
                <tr>
                    <td>I love to work in a team environment and have worked with teams during my Co-Ops and study</td>
                </tr>
                <tr>
                    <td>I try to learn and solve problems myself and require little supervision to do the task</td>
                </tr>
                <tr>
                    <td>I enjoy learning new things from others and I also tend to give my input where needed</td>
                </tr>
                <tr>
                    <td>I am approachable and I keep myself open to my co-workers and others</td>
                </tr>
            </tbody>
            </table>
            <br />
            <h5><b>Technical Skills</b></h5>
            <h6><b><u>Programming Frameworks</u></b></h6>
                <table className='table table-striped'>
                    <thead>
                    <tr>
                        <th scope='col'>Framework</th>
                    {/* <th>Level</th> */}
                        <th scope='col'>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Asp.Net &nbsp;<a href='https://dotnet.microsoft.com/'><img src={dotnetLogo} style={{width:'40px',height:'40px'}}></img></a></td>
                        <td>Acquired the experience while working as Software Developer at various workplaces</td>
                    </tr>
                    <tr>
                        <td>React.JS<a href='https://reactjs.org/'><img src={reactLogo} style={{width:'40px',height:'40px'}}></img></a></td>
                        <td>Self learnt while making personal projects including this website and got experience while working as developer</td>
                    </tr>
                    <tr>
                        <td>Spring Boot <a href='https://spring.io/'><img src={sbLogo} style={{width:'60px',height:'40px'}}></img></a></td>
                        <td>Completed training in this framework (complete course) and did job as a Java Consultant while working at TCS</td>
                    </tr>
                    <tr>
                        <td>Ktor <a href='https://ktor.io/'><img src={ktorLogo} style={{width:'60px',height:'40px'}}></img></a></td>
                        <td>Self learning and making progress, you can refer my github for latest projects</td>
                    </tr>
                    <tr>
                        <td>React Native <a href='https://reactnative.dev/'><img src={rnLogo} style={{width:'60px',height:'40px'}}></img></a></td>
                        <td>Built a mobile app for my Final Year Project using React Native and Google Firebase</td>
                    </tr>
                    </tbody>
                </table>
                <br />
                <h6><b><u>Programming Languages</u></b></h6>
                <table className='table table-striped'>
                    <thead>
                    <tr>
                        <th scope='col'>Languages</th>
                        <th scope='col'>Level</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>C#</td>
                        <td>Experienced</td>
                    </tr>
                    <tr>
                        <td>JavaScript, Java, C, Kotlin</td>
                        <td>Proficient</td>
                    </tr>
                    <tr>
                        <td>HTML,CSS</td>
                        <td>Advanced</td>
                    </tr>
                    <tr>
                        <td>PHP, Ruby, Lua</td>
                        <td>Intermediate</td>
                    </tr>
                    </tbody>
                </table>
                <br />
                <h6><b><u>IT Skills</u></b></h6>
                • Deploying web apps on various servers including <b>IIS Web Server</b>, <b>Nginx</b>, <b>Apache</b>, and <b>Google Firebase</b>.<br />
                • Setting up proper development environment for various technologies including <b>.Net</b>, <b>React</b>, <b>React Native</b>, and <b>Node JS</b>. <br />
                • Dealt with <b>firewalls</b> on <b>home server</b> and <b>network devices</b>.<br />
                • Installed and configured various servers including <b>web servers</b> and <b>FTP servers</b> on my <b>home server</b>.<br />
                • Configured <b>remote logins</b> for my home PC.<br /> 
                • By applying various networking concepts exposed the <b>ports</b> of my <b>home network</b> for <b>serving websites</b> and <b>RDI client</b> for <b>remote connections</b>.<br />
            </div>
            {/* <div className='divStyle'>
                <p>Click on the button to Download my Resume</p>
                <a className='btn btn-primary my-2' href={res} download>Resume</a>
            </div> */}
            
        </div>
        <div className='mb-0'>
            {/* <Footer /> */}
            </div>
        </div>
    );
};
