export default function TopHeader() {
    return(
        <div>
            <div className='text-center'>
            <h3>Muhammad Faraz Sohail</h3>
            <p>Software Engineer, Web developer, and IT enthusiast</p>
            </div>
            
        </div>
    );
};
