import tenloIcon from './pics/logoG.png';
import projImg  from './pics/projectsImg.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import THeader from './TopHeader';
import Footer from './Footer';

export default function Projects() {
    return(
        <div>
        <div className='container' style={{marginBottom:'6%'}}>
            {/* <div className='mx-auto my-auto' style={{backgroundImage:`url(${projImg})`,backgroundRepeat:"no-repeat",height:'400px',backgroundSize:'cover',backgroundAttachment:'fixed'}}> */}
                <THeader />
                    <div>
            <h3 className='text-center text-muted' style={{color:'',fontWeight:'lighter', fontSize:'50px',fontFamily:'sans-serif'}}>Projects</h3>
            </div>
            {/* </div> */}
            
    <div className='divStyle' >
        <div>
            <h4>Students Data Preview (React App)</h4>
            • Built React App to show the random data from public API.<br />
            • Added styling(css) to the make the app look more appealing.<br />
            • Added search by name functionality to client side whcich can search the data using names.<br />
            • Built more functionality to add temporary data tags to the client side which can be searched through search tags bar.<br />
            <b>Preview Website:&nbsp;&nbsp;<a href='https://studentsdatapreview.web.app'>www.studentsdatapreview.web.app</a></b>            
        </div>
        <br />
        <div>
       <h4> Tenlo <img src={tenloIcon} style={{width:'60px',height:'50px'}}></img></h4>
•	Built a React-native mobile app for Tenants and Landlords to communicate as a final year project.<br />
•	Front-end is built using React native while backend is handled by Firebase Realtime Database<br /> 
<b>Introduction Website:&nbsp;&nbsp;<a href='https://www.tenlo.ca'>www.tenlo.ca</a></b>
</div>
<br />
<div>
<h4>Web Development</h4>
<h5><b>-Student Academic Helping site</b></h5>
•	Group project in React.js helping student in their academic decisions (prototype only) <br />
<b>Repository:&nbsp;&nbsp;<a href='https://github.com/uWindsor-3110-Final-Project'>Project Repository</a></b><br />
<h5><b>-Story Pitch website for media class</b></h5>
• Made a website to pitch my story to the producers to finance the short film that I wrote<br />
<b>Website:&nbsp;&nbsp;<a href='http://sohailm.myweb.cs.uwindsor.ca/media/'>Media Site</a></b>

        </div>
<br />
<div>
    <h4>Api Development</h4>
    <h5><b>-Url Shortner</b></h5>
    • Using .Net Core 5 and C# built a REST Api which handled the https requests <br /> 
    • Its An api that shortens the Url and send back the short url response in JSON format<br />
    • It also redirects the user to the targeted url using short url, that is server side redirection<br /> 
    <b>Repository:&nbsp;&nbsp;<a href='https://github.com/muhammadfarazsohail/UrlShortner'>Url Shortner</a></b><br />
</div><br />
<div>
<h4>Game Development</h4>
•	Developed Space shooter Game, Rock Paper Scissor game using Unity and C#<br />
•   I used Unity Engine to make the games, including textures and coding<br />
<b>Repository:&nbsp;&nbsp;<a href='https://github.com/muhammadfarazsohail/RockPaperScissor'>Rock Paper Scissor</a></b><br />
</div>
<br />

    </div>
    
    </div>
    <div>
        {/* <Footer /> */}
    </div>
    </div>
    );
};
