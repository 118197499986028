import 'bootstrap/dist/css/bootstrap.min.css';
import dp from "./pics/IMG_1145.jpg";
import Dp from './Dpic';
import bgImg from "./pics/mySiteBG2.png";
import gitIcon from './pics/gitIcon.png';
import linkIcon from './pics/linkedInIcon.png';
import fbIcon from './pics/fbIcon.png';
import instaIcon from './pics/instaIcon.jpg';
import './home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import Footer from './Footer';
export default function Home() {

    return(
        <div>
        <div className='container'>
            <head>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1461898983747739"
     crossorigin="anonymous"></script>
            </head>
            <br />
            {/* <div style={{backgroundImage:`url(${bgImg})`,backgroundRepeat:"no-repeat",backgroundAttachment:'fixed',backgroundColor:'white'}}> */}
            <div >
                {/* <img src={dp} className="img-fluid rounded mx-auto d-block" height='auto' width='600'></img> */}
                <Dp />
            {/* <h4 style={{marginTop:"8px",marginLeft:"100px"}}><b>Muhammad Faraz Sohail</b></h4> */}
            </div>
            {/* </div> */}
            <div className='divStyle'>
                <h5><b>Introduction</b></h5>
                <i><p>Hi, My name is <b>Muhammad Faraz Sohail</b>. I am a <b>Software Engineer</b> and I graduated from the <b>University of Windsor</b>. I enjoy coding and building things.<br />
                I like to learn new Technologies and new programming Languages. I like to read spy novels. I like to watch action Anime and movies during my free time (Any suggestions, please feel free to tell me).<br />
                I like to build websites as well as mobile apps, but I'm in the process of learning so who knows what I end up liking!! Technology facinates me.... <br /><br />

                New Adventures await!!!! 
                </p></i>&#128519;
            </div>

            <div className='divStyle' style={{width:'60%'}} >
                <h5><b>Social Media</b></h5>
                <a className='my-3 btn' href='https://github.com/muhammadfarazsohail'>Github <img src={gitIcon} style={{width:'40px',height:'40px'}}></img></a>
                <a className='my-3 btn' href='https://www.linkedin.com/in/muhammadfarazsohail/'>LinkedIn <img src={linkIcon} style={{width:'40px',height:'40px'}}></img></a>
                <a className='my-3 btn' href='https://www.facebook.com/mfaraz.sohail'>Facebook <img src={fbIcon} style={{width:'40px',height:'40px'}}></img></a>
                <a className='my-3 btn' href='https://www.instagram.com/muhammadfarazsohail/'>Instagram <img src={instaIcon} style={{width:'40px',height:'40px'}}></img></a>
                
            </div>
            
            {/* <div className='divStyle' >
                <h5><b>
                    Education
                    </b>
                </h5>
                <div>
                    <h6>University</h6>
                    <h7><b>University of Windsor (Ontario, Canada)</b></h7>
                    <i><p>BSc[H] Computer Science with Software Engineering Specialization and Minor in Business Administration</p></i>
                </div>
                <br />
                <div>
                    <h6>High School</h6>
                    <h7><b>Garrison Academy Khariant Cantt (Punjab, Pakistan)</b></h7>
                    <i><p>FSc Pre-Medical Diploma</p></i>
                    <p></p>
                </div>
                <br />
                <div>
                    <h6>School</h6>
                    <h7><b>The Educators Al-Mustafa Campus Sarai Alamgir (Punjab, Pakistan)</b></h7>
                    <i><p>School with focus on Biology, Chemistry and Physics</p></i>
                </div>
            </div> */}
            
        </div>
                <div>
                {/* <Footer /> */}
                </div>
        </div>
    );
    
};
