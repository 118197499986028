import wfcuLogo from './pics/wfcuLogo.jpg';
import sekureLogo from './pics/sekuremerchants.png';
import nffLogo from './pics/nffLogo.png';
import uwinLogo from './pics/uwindsorLogo.png';
import tcsLogo from './pics/tcsLogo.png';
import expImg from './pics/experienceImg.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import THeader from './TopHeader';
import Footer from './Footer';

export default function Experience() {
    return(
        <div>
            <head>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1461898983747739"
     crossorigin="anonymous"></script>
            </head>
    <div className='container' style={{marginBottom:'8%'}}>
        {/* <div style={{backgroundImage:`url(${expImg})`,backgroundRepeat:"no-repeat",height:'400px',backgroundSize:'cover',backgroundAttachment:'fixed'}}> */}
                <THeader />
                <div>
        <h3 className='text-center text-muted' style={{color:'',fontWeight:'lighter', fontSize:'50px',fontFamily:'sans-serif'}}>Experience</h3>
        </div>
        {/* </div> */}
    <div className='divStyle'>
    <div>
            <h4><u>Software Developer</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;January 2023 – Present <a href='https://www.tcs.com/'><img src={tcsLogo} style={{width:'60px',height:'60px'}}></img></a></h4>
            <h5>Tata Consultancy Services, Toronto, ON</h5>
            <h6> .Net Developer (Toronto Dominion (TD) Bank : October 2023 - Present)</h6>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•   Developing applications and integrating databases using .NET Technologies(C# and VB.NET) <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•   Writing and optimizing stored procedures in MSSQL <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•   Utilizing JIRA for task coordination, Confluence for project documentation, and Bitbucket for code versioning and repository management <br />
            <h6 style={{"margin-top":"5px"}}>  Java Developer (Loblaws: January 2023 - September 2023)</h6>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•   Designed, developed and maintained Spring web application using microservice architecture <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•   Implemented restful APIs using Java and consumed them on the client side using React.js<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•   Fixed bugs, wrote unit tests and feature documentation <br />
        </div>
        <br />
        <div>
            <h4><u>Backend Developer</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;February 2022 – November 2022 <a href='https://sekuremerchants.com/'><img src={sekureLogo} style={{width:'150px',height:'60px'}}></img></a></h4>
            <h5>Sekure Merchant Solutions, Montréal, QC</h5>
            •   Developed and maintained domain-driven design .NET Apps using ABP Framework with Agile methodology <br />
            •   Tested and developed Rest APIs to be consumed by the CRM website <br />
            •   Developed plugin components for Twilio Flex using React and creating flows in Twilio Studio <br />
            •   Used Azure DevOps for version control (Git), task handling and deployments on Azure  <br />
        </div>
        <br />
        <div>
            <h4><u>Systems Developer</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;September 2021 – February 2022 <a href='https://www.wfcu.ca/'><img src={wfcuLogo} style={{width:'100px',height:'60px'}}></img></a></h4>
            <h5>Windsor Family Credit Union (WFCU), Windsor, ON</h5>
            •   Worked on .NET Projects for the clients<br />
            •   Developed new features for the web apps based on given user stories<br />
            •   Worked with Azure DevOps to handle the work and code base <br />
            •   Worked in an Agile environment, following the Scrum Framework<br />
            •   Maintained existing apps and building new features in ASP.NET framework<br />
            •   Worked closely with clients to provide quick solutions and the getting right requirements for the software<br />
        </div>
        <br />
        <div>
            <h4><u>Startup Owner</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;May 2021 – August 2021</h4><br />
            <h5>2021 Summer RBC EPIC FOUNDERS PROGRAM (EPI Center, Windsor, ON)</h5>
            •	Worked further on my final-year project in React Native with backend of Google Firebase NoSQL.<br />
            •	Gained the experience of going through a startup process. <br />

        </div>
        <br />
        <div >
      <h4><u>Systems Developer</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;January 2020 – March 2020 <a href='https://www.wfcu.ca/'><img src={wfcuLogo} style={{width:'100px',height:'60px'}}></img></a></h4>
<h5>Windsor Family Credit Union (WFCU), Windsor, ON</h5>
•	Using ASP.NET Technology and MSSQL DB built a Web App portal to handle Cheque Requests and Orders <br />
•	Built a Plugin using JavaScript and HTML to search the database Asynchronously using AJAX and .Net Core API<br />
•	Built Backend using C# and ensured the security of DB from injections and other attacks using Entity Framework<br />
•	Added more functionality and modified existing Web Apps<br />
•	Deployed the Web Apps on IIS Server <br />
•	Documented the new applications, and changes made in the applications.<br />
</div>
<br />
<div>
<h4><u>IT and Network Technician Intern</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;June 2019 – August 2019 <a href='https://www.naturefresh.ca/'><img src={nffLogo} style={{width:'100px',height:'60px'}}></img></a></h4>
<h5>Nature Fresh Farms, Leamington, ON</h5>
•	Laid wiring to connect new devices to the Network and managed existing devices (wired and wireless)<br />
•	Performed troubleshooting remotely and physically<br />
•	Ensured connectivity of old and new switches and routers.<br />
•	Programmed physical firewalls and documented the work done.<br />
</div>
<br />
<div>
<h4><u>Teaching Assistant</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;September 2018 – April 2021 <a href='https://www.uwindsor.ca/'><img src={uwinLogo} style={{width:'200px',height:'60px'}}></img></a></h4>
<h5>University of Windsor, Windsor, ON</h5>
•	Conducted lab tutorials and assisted students in the learning process<br />
•	Participated in assessment process and provided effective timely and appropriate feedback to students to support their learning<br />
</div>
    </div>
    <br />

</div>
<div>
    {/* <Footer /> */}
</div>
</div>
    );
};
